@charset "UTF-8";


$font_black: #070002;
// $font_normal: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
$font_normal: "メイリオ", "Meiryo", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", sans-serif;
$lato: 'Lato', sans-serif;
$din: "din-condensed",sans-serif;
$bg_gray: #f5f5f5;
$key_color: #c02829;
$border_gray: #dedede;
