@charset "UTF-8";
@import "_variable.scss";


html {
  font-size: 62.5%;
}
body {
  min-width: 1200px;
  position: relative;
  background: #fff;
  color: $font_black;
  font-size: 1.3rem;
  font-family: $font-normal;
}

a {
  display: inline-block;
  height: 100%;
  color: $font_black;
  &:focus {
    outline: 0;
  }
  &:hover {
    opacity: 0.7;
  }
}


/* header
------------------------------------*/
.header {
  width: 100%;
  height: auto;
  &-topinner1 {
    width: 100%;
    height: 140px;
    position: fixed;
    z-index: 9999;
    background-color: #fff;
    border-top: 3px solid $key_color;
    box-sizing: border-box;
  }
  &-topinner2 {
    width: 1200px;
    height: 140px;
    margin: 0 auto;
    position: relative;

  }
  &__search {
    position: absolute;
    top: 32px;
    left: 0;
    .form {
      display: flex;
    }
    .text {
      padding-left: 20px;
      position: relative;
      border: 0;
      box-sizing: border-box;
      transition: width 0.3s ease;
      background-color: #f0f0f0;
      &.open {
        width: 370px;
      }
    }
    .text, .submit, .btn {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      border: 0;
      outline: none;
    }
    .submit, .btn {
      position: absolute;
      top: 0;
      right: 0;
      background: #f0f0f0 url(../images/common/ic_search.svg) no-repeat center center;
    }
    .btn {
      &:hover {
        cursor: pointer;
      }
      &.open {
        display: none;
      }
    }
  }
  &__logo {
    padding-top: 39px;
    text-align: center;
    a {
      display: inline;
    }
  }
  &-topinner3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 145px;
    position: absolute;
    top: 21px;
    right: 0;
  }
  &__sns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 153px;
    padding-top: 10px;
    line-height: 0;
  }
  &__others {
    .font-size {
      display: flex;
      align-items: baseline;
      justify-content: flex-end;
      // padding-bottom: 10px;
      font-family: $lato;
      font-weight: bold;
      color: #999;
      .normal {
        padding-right: 20px;
        font-size: 1.4rem;
      }
      .large {
        font-size: 1.8rem;
      }
      .normal:hover, .large:hover {
        opacity: 0.7;
        cursor: pointer;
      }
      .select {
        color: $font_black;
      }
    }
    .languages {
      position: absolute;
      top: 45px;
      right: 110px;
      .btn {
        padding-right: 20px;
        position: relative;
        &::after {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 5px 0 5px;
          border-color: #333333 transparent transparent transparent;
          position: absolute;
          top: 8px;
          right: 0;
        }
      }
      .btn:hover, .language:hover {
        opacity: 0.7;
        cursor: pointer;
      }
      .subNavigation {
        width: 133px;
        padding: 0 10px;
        position: absolute;
        top: 30px;
        left: -20px;
        background: #f5f5f5;
        box-sizing: border-box;
        display: none;
        z-index: 2;
        text-align: center;
        box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
        &__item {
          padding: 10px 0;
          border-bottom: dotted 1px #999;
          &:last-child {
            border-bottom: 0;
          }
          &:hover {
            opacity: .8;
          }
          .subNavigation__item a {
            color: #fff;
          }
        }
      }
    }
  }
  &__menu {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background-color: $key_color;
    position: relative;
    text-align: center;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
    .btn {
      content: "";
      width: 18px;
      height: 18px;
      background: url(../images/common/ic_menu.svg) no-repeat center center;
      position: absolute;
      top: 14px;
      left: 21px;
    }
    &::after {
      content: "MENU";
      width: 60px;
      height: 60px;
      font-family: $lato;
      font-size: 0.8rem;
      color: #fff;
      position: absolute;
      top: 36px;
      left: 0;
    }
    &.open .btn {
      background: none;
      width: 21px;
      height: 4px;
      background-color: #fff;
      top: 22px;
      left: 19px;
    }
    &.open::after {
      content: "CLOSE";
    }
  }
  &-bottominner {
    width: 100%;
    height: auto;
    position: absolute;
    top: 140px;
    left: 0;
    box-sizing: border-box;
    transition: top 0.6s ease;
  }
  &__alert {
    display: none;
    font-size: 1.8rem;
    line-height: 2.8rem;
    width: 100%;
    height: auto;
    margin-bottom: 25px;
    background-color: #f0f0f0;
    &-inner {
      width: 1200px;
      margin: 0 auto;
      padding: 30px 0;
    }
    a {
      display: inline-block;
      position: relative;
      padding-right: 20px;
      text-decoration: underline;
      // &:after {
      //   content: "";
      //   display: inline-block;
      //   width: 0;
      //   height: 0;
      //   border-style: solid;
      //   border-width: 6px 0 6px 7px;
      //   border-color: transparent transparent transparent #000000;
      //   position: absolute;
      //   right: 0;
      //   top: calc(50% - 8px);
      // }
    }
  }
  &__subcatch {
    display: none;
    justify-content: center;
    padding-bottom: 20px;
    &-inner {
      font-size: 1.4rem;
      .bold {
        font-weight: bold;
      }
      .strong {
        color: #e31415;
      }
      dt, dd, {
        display: inline-block;
      }
      dd {
        padding: 0 15px 0 10px;
      }
    }
    .lighter {
      padding-left: 10px;
      font-size: 1.1rem;
      color: #666;
    }
  }
  &__nav {
    border-top: 1px solid #dedede;
    &-inner {
      display: flex;
      align-items: baseline;
      justify-content: center;
      width: 980px;
      height: 110px;
      margin: 0 auto;
      padding-top: 20px;
      box-sizing: border-box;
      .nav {
        margin: 0 25px;
        line-height: 2.5rem;
        text-align: center;
      }
    }
  }
  &__slidenav {
    opacity: 0;
    width: 100%;
    height: auto;
    padding-bottom: 40px;
    position: absolute;
    top: 137px;
    left: 0;
    background-color: #f5f5f5;
    &-inner1 {
      @extend .header__nav-inner;
      padding-top: 40px;
      .nav {
        @extend .nav;
      }
    }
    &-inner2 {
      @extend .header__nav-inner;
      align-items: center;
      width: 870px;
      height: auto;
      padding-top: 40px;
      .nav {
        font-size: 1.4rem;
      }
      .card {
        width: 110px;
        height: 100px;
        border-radius: 10px;
        background-color: #fff;
        text-align: center;
        margin-right: 14px;
        &:last-child {
          margin-right: 0;
        }
        &__ic {
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: auto;
          height: 75px;
          &.ajust {
            height: 60px;
          }
        }
        &__txt {
          font-size: 1.2rem;
          line-height: 0;
          .ajust {
            display: inline-block;
            padding-top: 15px;
          }
        }
        a {
          display: block;
        }
        &:hover {
          background-color: $key_color;
          .card__txt {
            color: #fff;
          }
          svg {
            fill:#fff;
          }
        }
        a:hover {
          opacity: 1;
        }
      }
    }
  }
}

/* svg
------------------------------------*/
.header__slidenav-inner2 svg {
  fill:$key_color;
  // stroke:$key_color;
  // stroke-linecap:round;
  // stroke-width:3px;
  &.icon_goods {
    width: 39px;
    height: 39px;
  }
  &.icon_western {
    width: 49px;
    height: 33px;
  }
  &.icon_school {
    width: 35px;
    height: 35px;
  }
  &.icon_service {
    width: 47px;
    height: 11px;
  }
}


/* nav--b-wrapper
------------------------------------*/
.nav--b-wrapper {
  .nav--b {
    &--card {
      &:hover {
        background-color: $key_color;
        .nav--b--card__txt {
          color: #fff;
        }
        svg {
          fill:#fff;
        }
      }
      a:hover {
        opacity: 1;
      }
    }
  }
}


/* title
------------------------------------*/
.ttl--a {
  margin-bottom: 40px;
  .catch {
    font-family: $din;
    font-size: 5rem;
    font-weight: bold;
    letter-spacing: 3.7px;
  }
  .subcatch {
    display: inline-block;
    padding-left: 32px;
    vertical-align: 10px;
    font-size: 2.1rem;
    font-weight: normal;
    color: $key_color;
    letter-spacing: 0.1em;
  }
}
.ttl--b {
  margin-bottom: 25px;
  line-height: 3.5rem;
  text-align: center;
  .catch {
    font-family: $din;
    font-size: 5rem;
    font-weight: bold;
    letter-spacing: 4px;
  }
  .subcatch {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: normal;
  }
}
.ttl--c-wrapper {
  width: 100%;
  height: 230px;
  padding: 90px 0 70px;
  // background: url(../images/common/bg_ttl_01.jpg) no-repeat center center;
  background: url(../images/common/pttl_bg.jpg) no-repeat center center;
  background-size: cover;
  box-sizing: border-box;
  .ttl--c {
    margin-bottom: 25px;
    line-height: 3.5rem;
    text-align: center;
    .catch {
      font-family: $din;
      font-size: 5rem;
      font-weight: bold;
      letter-spacing: 4px;
    }
    .subcatch {
      display: inline-block;
      font-size: 1.4rem;
      font-weight: normal;
    }
  }
}
.ttl--d {
  padding-bottom: 4px;
  border-bottom: solid 2px #000;
  font-size: 2.4rem;
  font-weight: bold;
  letter-spacing: 0.05em;
}

/* breadcrumb
------------------------------------*/
.breadcrumb-wrapper {
  padding: 30px 0;
  font-size: 1.2rem;
  color: #999;
  .breadcrumb {
    width: 1200px;
    height: auto;
    margin: 0 auto;
    &__item {
      display: inline-block;
      padding-right: 40px;
      a {
        color: #999;
      }
    }
    .arrow {
      display: inline-block;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: -7px;
        left: -26px;
        width: 5px;
        height: 5px;
        margin: auto;
        vertical-align: middle;
        border-top: 1px solid #999;
        border-right: 1px solid #999;
        transform: rotate(45deg);
      }
    }
  }
}


/* card
------------------------------------*/
.card--c {
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
  .card {
    width: 280px;
    height: auto;
    margin-bottom: 30px;
    margin-right: 26px;
    position: relative;
    z-index: 1;
    word-break: break-all;
    &:nth-child(4n) {
      margin-right: 0;
    }
    &.new::after {
      content: "NEW";
      width: 60px;
      height: 60px;
      position: absolute;
      top: -10px;
      left: -10px;
      border-radius: 30px;
      background-color: #ff7200;
      font-family: $lato;
      font-size: 1.5rem;
      color: #fff;
      text-align: center;
      line-height: 60px;
    }
    &-inner1 {
      position: relative;
      overflow: hidden;
    }
    &__photo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 280px;
      height: 280px;
      &.blur::after {
        content: "";
        width: 280px;
        height: 280px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        background: url(../images/index/img_eventnews_02.jpg) no-repeat center center;
        background-size: cover;
        filter: blur(1px);
      }
    }
    &-inner2 {
      padding-top: 25px;
    }
    &__subttl {
      margin-bottom: 10px;
      font-family: $lato;
      font-size: 1.3rem;
    }
    &__ttl {
      margin-bottom: 10px;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
    &__info {
      font-style: italic;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: $key_color;
    }
    img {
      transition: transform 0.7s ease;
    }
    &:hover img {
      transform: scale(1.2);
    }
  }
}

.card-d {
  margin-bottom: 40px;
  padding: 25px 30px 30px;
  border: solid 2px $key_color;
  border-radius: 18px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  &__icon {
    margin-right: 25px;
  }
  &__body {
    width: calc(100% - 146px);
  }
  &__ttl {
    font-size: 1.8rem;
    font-weight: bold;
    letter-spacing: 0.05em;
  }
  &__txt {
    margin: 15px 0 10px;
    font-size: 1.6rem;
    letter-spacing: 0.05em;
    line-height: 1.7;
  }
  &__foot {
    font-size: 1.6rem;
    letter-spacing: 0.05em;
  }
  &__link {
    color: $key_color;
    text-decoration: underline;
  }
}


/* btn
------------------------------------*/
.btn--a {
  width: 300px;
  height: 40px;
  margin: 0 auto;
  border-radius: 20px;
  font-size: 1.3rem;
  line-height: 40px;
  background-color: #444;
  text-align: center;
  &:hover {
    opacity: 0.7;
  }
  a {
    display: block;
    color: #fff;
  }
}


/* footer
------------------------------------*/
.footer {
  width: 100%;
  // height: 700px;
  &-topinner {
    display: flex;
    width: auto;
    // height: 450px;
    background-color: $bg_gray;
    // border-bottom: 15px solid #d1001c;
  }
  &__map {
    width: 50%;
    height: auto;
  }
  &__mores-info {
    width: 50%;
    height: auto;
    padding: 80px 0;
    text-align: center;
    .logo {
      margin-bottom: 30px;
    }
    .address {
      margin-bottom: 40px;
      font-size: 1.6rem;
      line-height: 2.8rem;
    }
    .sns {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;
      .ic {
        padding: 0 20px;
      }
    }
    .others {
      // display: flex;
      // align-items: stretch;
      // justify-content: flex-start;
      // flex-wrap: wrap;
      // width: 470px;
      margin: 0 auto;
      text-align: center;
      .txt {
        display: inline-block;
        // width: 25%;
        padding: 0;
        font-size: 1.3rem;
        text-align: left;
        margin-right: 40px;
        &:last-child {
          margin-right: 0;
        }
        a {
          padding: 8px 0 8px 15px;
        }
        .arrow {
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: 13px;
            left: 0;
            width: 6px;
            height: 6px;
            margin: auto;
            vertical-align: middle;
            border-top: 2px solid $font_black;
            border-right: 2px solid $font_black;
            transform: rotate(45deg);
          }
        }
      }
    }
  }
  &-bottominner {
    width: auto;
    // height: 230px;
  }
  &__okadaya-info {
    display: flex;
    width: 900px;
    height: 170px;
    margin: 0 auto;
    padding: 60px 0;
    box-sizing: border-box;
    .info {
      padding-left: 45px;
      font-size: 1.6rem;
      line-height: 3.2rem;
      a {
        display: inline;
      }
    }
  }
  &__copyright {
    width: auto;
    height: 60px;
    padding: 25px 0;
    // border-top: 1px solid #dedede;
    font-family: $lato;
    font-size: 11px;
    text-align: center;
    box-sizing: border-box;
  }
}

#pageTop {
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 9999;
  a {
    display: block;
    z-index: 999;
    border-radius: 30px;
    width: 50px;
    height: 50px;
    background-color: #444;
    color: #fff;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    box-shadow: 0 2px 15px rgba(0,0,0,0.3);
    &:hover {
      text-decoration: none;
      opacity: 0.7;
    }
  }
  .arrow {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 20px;
      left: 17px;
      width: 14px;
      height: 14px;
      margin: auto;
      vertical-align: middle;
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      transform: rotate(-45deg);
    }
  }
}

.btn-wrapper {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  // width: 1200px;
  // height: auto;
  display: table;
  width: 1200px;
  height: auto;
  padding: 0 0 40px;
  box-sizing: border-box;
  margin: 0 auto 80px;
  &__item {
    display: table-cell;
    width: 600px;
    text-align: center;
    &_l {
      width: 300px;
      text-align: left;
    }
    &_r {
      width: 300px;
      text-align: right;
    }
  }
  .btn--a {
    margin: 0;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 15px;
      width: 7px;
      height: 7px;
      margin: auto;
      vertical-align: middle;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
    }
    &.prev::after {
      left: 85px;
      transform: rotate(225deg);
    }
    &.next::after {
      right: 85px;
      transform: rotate(45deg);
    }
  }
}


.section {
  word-wrap: break-word;
  overflow-wrap : break-word;

}

.card--a .card__photo .blur {
  display: none !important;
}

.card--a .card:hover img,
.card--b .card:hover img,
.card--c .card:hover img {
  transform: scale(1)!important;
}

/*************************************
helper classes
*************************************/
.mt00 { margin-top: 0 !important; }
.mt03 { margin-top: 3px !important; }
.mt05 { margin-top: 5px !important; }
.mt10 { margin-top: 10px !important; }
.mt15 { margin-top: 15px !important; }
.mt20 { margin-top: 20px !important; }
.mt25 { margin-top: 25px !important; }
.mt30 { margin-top: 30px !important; }
.mt35 { margin-top: 35px !important; }
.mt40 { margin-top: 40px !important; }
.mt45 { margin-top: 45px !important; }
.mt50 { margin-top: 50px !important; }
.mt55 { margin-top: 55px !important; }
.mt60 { margin-top: 60px !important; }
.mt65 { margin-top: 65px !important; }
.mt70 { margin-top: 70px !important; }
.mt75 { margin-top: 75px !important; }
.mt80 { margin-top: 80px !important; }
.mt85 { margin-top: 85px !important; }
.mt90 { margin-top: 90px !important; }
.mt95 { margin-top: 95px !important; }
.mt100 { margin-top: 100px !important; }
.mb00 { margin-bottom: 0 !important; }
.mb05 { margin-bottom: 5px !important; }
.mb10 { margin-bottom: 10px !important; }
.mb15 { margin-bottom: 15px !important; }
.mb20 { margin-bottom: 20px !important; }
.mb25 { margin-bottom: 25px !important; }
.mb30 { margin-bottom: 30px !important; }
.mb35 { margin-bottom: 35px !important; }
.mb40 { margin-bottom: 40px !important; }
.mb45 { margin-bottom: 45px !important; }
.mb50 { margin-bottom: 50px !important; }
.mb55 { margin-bottom: 55px !important; }
.mb60 { margin-bottom: 60px !important; }
.mb65 { margin-bottom: 65px !important; }
.mb70 { margin-bottom: 70px !important; }
.mb75 { margin-bottom: 75px !important; }
.mb80 { margin-bottom: 80px !important; }
.mb85 { margin-bottom: 85px !important; }
.mb90 { margin-bottom: 90px !important; }
.mb95 { margin-bottom: 95px !important; }
.mb100 { margin-bottom: 100px !important; }
.ml00 { margin-left: 0 !important; }
.ml05 { margin-left: 5px !important; }
.ml10 { margin-left: 10px !important; }
.ml15 { margin-left: 15px !important; }
.ml20 { margin-left: 20px !important; }
.ml25 { margin-left: 25px !important; }
.ml30 { margin-left: 30px !important; }
.ml35 { margin-left: 35px !important; }
.ml40 { margin-left: 40px !important; }
.ml45 { margin-left: 45px !important; }
.ml50 { margin-left: 50px !important; }
.ml55 { margin-left: 55px !important; }
.ml60 { margin-left: 60px !important; }
.ml65 { margin-left: 65px !important; }
.ml70 { margin-left: 70px !important; }
.ml75 { margin-left: 75px !important; }
.ml80 { margin-left: 80px !important; }
.ml85 { margin-left: 85px !important; }
.ml90 { margin-left: 90px !important; }
.ml95 { margin-left: 95px !important; }
.ml100 { margin-left: 100px !important; }
.mr00 { margin-right: 0 !important; }
.mr05 { margin-right: 5px !important; }
.mr10 { margin-right: 10px !important; }
.mr15 { margin-right: 15px !important; }
.mr20 { margin-right: 20px !important; }
.mr25 { margin-right: 25px !important; }
.mr30 { margin-right: 30px !important; }
.mr35 { margin-right: 35px !important; }
.mr40 { margin-right: 40px !important; }
.mr45 { margin-right: 45px !important; }
.mr50 { margin-right: 50px !important; }
.mr55 { margin-right: 55px !important; }
.mr60 { margin-right: 60px !important; }
.mr65 { margin-right: 65px !important; }
.mr70 { margin-right: 70px !important; }
.mr75 { margin-right: 75px !important; }
.mr80 { margin-right: 80px !important; }
.mr85 { margin-right: 85px !important; }
.mr90 { margin-right: 90px !important; }
.mr95 { margin-right: 95px !important; }
.mr100 { margin-right: 100px !important; }
.pt00 { padding-top: 0 !important; }
.pt05 { padding-top: 5px !important; }
.pt10 { padding-top: 10px !important; }
.pt15 { padding-top: 15px !important; }
.pt20 { padding-top: 20px !important; }
.pt25 { padding-top: 25px !important; }
.pt30 { padding-top: 30px !important; }
.pt35 { padding-top: 35px !important; }
.pt40 { padding-top: 40px !important; }
.pt45 { padding-top: 45px !important; }
.pt50 { padding-top: 50px !important; }
.pt55 { padding-top: 55px !important; }
.pt60 { padding-top: 60px !important; }
.pt65 { padding-top: 65px !important; }
.pt70 { padding-top: 70px !important; }
.pt75 { padding-top: 75px !important; }
.pt80 { padding-top: 80px !important; }
.pt85 { padding-top: 85px !important; }
.pt90 { padding-top: 90px !important; }
.pt95 { padding-top: 95px !important; }
.pt100 { padding-top: 100px !important; }
.pb00 { padding-bottom: 0 !important; }
.pb05 { padding-bottom: 5px !important; }
.pb10 { padding-bottom: 10px !important; }
.pb15 { padding-bottom: 15px !important; }
.pb20 { padding-bottom: 20px !important; }
.pb25 { padding-bottom: 25px !important; }
.pb30 { padding-bottom: 30px !important; }
.pb35 { padding-bottom: 35px !important; }
.pb40 { padding-bottom: 40px !important; }
.pb45 { padding-bottom: 45px !important; }
.pb50 { padding-bottom: 50px !important; }
.pb55 { padding-bottom: 55px !important; }
.pb60 { padding-bottom: 60px !important; }
.pb65 { padding-bottom: 65px !important; }
.pb70 { padding-bottom: 70px !important; }
.pb75 { padding-bottom: 75px !important; }
.pb80 { padding-bottom: 80px !important; }
.pb85 { padding-bottom: 85px !important; }
.pb90 { padding-bottom: 90px !important; }
.pb95 { padding-bottom: 95px !important; }
.pb100 { padding-bottom: 100px !important; }
.pl00 { padding-left: 0 !important; }
.pl05 { padding-left: 5px !important; }
.pl10 { padding-left: 10px !important; }
.pl15 { padding-left: 15px !important; }
.pl20 { padding-left: 20px !important; }
.pl25 { padding-left: 25px !important; }
.pl30 { padding-left: 30px !important; }
.pl35 { padding-left: 35px !important; }
.pl40 { padding-left: 40px !important; }
.pl45 { padding-left: 45px !important; }
.pl50 { padding-left: 50px !important; }
.pl55 { padding-left: 55px !important; }
.pl60 { padding-left: 60px !important; }
.pl65 { padding-left: 65px !important; }
.pl70 { padding-left: 70px !important; }
.pl75 { padding-left: 75px !important; }
.pl80 { padding-left: 80px !important; }
.pl85 { padding-left: 85px !important; }
.pl90 { padding-left: 90px !important; }
.pl95 { padding-left: 95px !important; }
.pl100 { padding-left: 100px !important; }
.pr00 { padding-right: 0 !important; }
.pr05 { padding-right: 5px !important; }
.pr10 { padding-right: 10px !important; }
.pr15 { padding-right: 15px !important; }
.pr20 { padding-right: 20px !important; }
.pr25 { padding-right: 25px !important; }
.pr30 { padding-right: 30px !important; }
.pr35 { padding-right: 35px !important; }
.pr40 { padding-right: 40px !important; }
.pr45 { padding-right: 45px !important; }
.pr50 { padding-right: 50px !important; }
.pr55 { padding-right: 55px !important; }
.pr60 { padding-right: 60px !important; }
.pr65 { padding-right: 65px !important; }
.pr70 { padding-right: 70px !important; }
.pr75 { padding-right: 75px !important; }
.pr80 { padding-right: 80px !important; }
.pr85 { padding-right: 85px !important; }
.pr90 { padding-right: 90px !important; }
.pr95 { padding-right: 95px !important; }
.pr100 { padding-right: 100px !important; }

/* px */
.fz10 { font-size: 10px !important; }
.fz11 { font-size: 11px !important; }
.fz12 { font-size: 12px !important; }
.fz13 { font-size: 13px !important; }
.fz14 { font-size: 14px !important; }
.fz15 { font-size: 15px !important; }
.fz16 { font-size: 16px !important; }
.fz17 { font-size: 17px !important; }
.fz18 { font-size: 18px !important; }
.fz19 { font-size: 19px !important; }
.fz20 { font-size: 20px !important; }
.fz21 { font-size: 21px !important; }
.fz22 { font-size: 22px !important; }
.fz23 { font-size: 23px !important; }
.fz24 { font-size: 24px !important; }
.fz25 { font-size: 25px !important; }
.fz26 { font-size: 26px !important; }
.fz27 { font-size: 27px !important; }
.fz28 { font-size: 28px !important; }
.fz29 { font-size: 29px !important; }
.fz30 { font-size: 30px !important; }
.fz31 { font-size: 31px !important; }
.fz32 { font-size: 32px !important; }
.fz33 { font-size: 33px !important; }
.fz34 { font-size: 34px !important; }
.fz35 { font-size: 35px !important; }
.fz36 { font-size: 36px !important; }
.fz37 { font-size: 37px !important; }
.fz38 { font-size: 38px !important; }
.fz39 { font-size: 39px !important; }
.fz40 { font-size: 40px !important; }

.lh00 { line-height: 0 !important; }
.lh11 { line-height: 1.1 !important; }
.lh12 { line-height: 1.2 !important; }
.lh13 { line-height: 1.3 !important; }
.lh14 { line-height: 1.4 !important; }
.lh15 { line-height: 1.5 !important; }
.lh16 { line-height: 1.6 !important; }
.lh17 { line-height: 1.7 !important; }
.lh18 { line-height: 1.8 !important; }
.lh19 { line-height: 1.9 !important; }
.lh20 { line-height: 2.0 !important; }
.lh21 { line-height: 2.1 !important; }
.lh22 { line-height: 2.2 !important; }
.lh23 { line-height: 2.3 !important; }
.lh24 { line-height: 2.4 !important; }
.lh25 { line-height: 2.5 !important; }

.ta-c { text-align: center !important; }
.ta-l { text-align: left !important; }
.ta-r { text-align: right !important; }
.dy-n { display: none !important; }
.dy-b { display: block !important; }
.dy-ib { display: inline-block !important; }
.dy-i { display: inline !important; }
.fl-l { float: left !important; }
.fl-r { float: right !important; }
.fl-n { float: none !important; }
.va-m { vertical-align: middle !important; }
.va-bl { vertical-align: baseline !important; }
.va-t { vertical-align: top !important; }
.va-b { vertical-align: bottom !important; }
.td-u { text-decoration: underline !important; }
.fw-b { font-weight: bold !important; }
.fw-n { font-weight: normal !important; }